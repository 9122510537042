import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import Link from "gatsby-link";
import Layout from "../components/layout/Layout";
import NavBar from "../components/navigations/NavBar";
import ReactTooltip from "react-tooltip";
import {getSocials} from "../utils/Common";
import ReCAPTCHA from "react-google-recaptcha";
const social = getSocials();
const recaptchaRef = React.createRef();

const Contact = () => {
    const [captcha, setCaptcha] = useState(null);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const onSubmit = (e) => {
        if(firstname === ''){
            setError(t("put_your_firstname"));
        }
        else if(lastname === ''){
            setError(t("put_your_lastname"));
        }
        else if(phone === ''){
            setError(t("put_your_phone"));
        }
        else if(email === ''){
            setError(t("put_your_email"));
        }
        else if(message === ''){
            setError(t("put_your_message"));
        }
        else if(captcha === null){
            setError(t("put_your_captcha"));
        }
        else if(firstname !== '' && lastname !== '' && phone !== '' && email !== '' && message !== '' && captcha !== null){

        }
        else{
            setError(t("put_all_fields"));
        }
    }

    const onChange = (value) => {
        setCaptcha(value)
    }
    const { t } = useTranslation();
    const d = new Date();
    let year = d.getFullYear();
    return (
        <Layout pageTitle={t("sepro")+" - "+t("contact")}>
            <div className="min-h-full bg-contact">
                <NavBar active={'contact'} page="contact" label={t("contact")}/>
                <div className="px-4 sm:p-10 bg-kayloo-2">
                    <div className="relative flex flex-col items-center max-w-3xl px-6 py-10 mx-auto text-left sm:my-5 lg:px-0">
                        <h1 className="max-w-3xl text-4xl font-bold tracking-tight text-gray-100 lg:text-5xl mt-10">{t("contact")} </h1>
                        <p className="max-w-full mt-4 text-base text-gray-400 text-justify">{t("put_fields_form_contact")}</p>
                        <form className="min-w-full" onSubmit={onSubmit} method="POST" autoComplete="off">
                            <div>
                                <div className="mt-10 border-t border-gray-200 pt-10">
                                    <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                        <div>
                                            <label htmlFor="first-name" className="block text-sm text-base text-gray-100">
                                                {t("first_name")} (<span className="text-red-500">*</span>)
                                            </label>
                                            <div className="mt-1">
                                                <input required value={firstname} onChange={e => setFirstname(e.target.value)}
                                                    type="text"
                                                    id="first-name"
                                                    name="first-name"
                                                    autoComplete="given-name"
                                                    className="block w-full border-gray-200 shadow-sm focus:ring-cyan-500 focus:border-cyan-500 sm:text-base p-4"
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <label htmlFor="last-name" className="block text-sm text-base text-gray-100">
                                                {t("last_name")} (<span className="text-red-500">*</span>)
                                            </label>
                                            <div className="mt-1">
                                                <input required value={lastname} onChange={e => setLastname(e.target.value)}
                                                    type="text"
                                                    id="last-name"
                                                    name="last-name"
                                                    autoComplete="family-name"
                                                    className="block w-full border-gray-200 shadow-sm focus:ring-cyan-500 focus:border-cyan-500 sm:text-base p-4"
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <label htmlFor="phone" className="block text-sm text-base text-gray-100">
                                                {t("phone")} (<span className="text-red-500">*</span>)
                                            </label>
                                            <div className="mt-1">
                                                <input required value={phone} onChange={e => setPhone(e.target.value)}
                                                    type="tel"
                                                    id="phone"
                                                    name="phone"
                                                    autoComplete="tel"
                                                    className="block w-full border-gray-200 shadow-sm focus:ring-cyan-500 focus:border-cyan-500 sm:text-base p-4"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="email" className="block text-sm font-medium text-gray-100">
                                                {t("email")} (<span className="text-red-500">*</span>)
                                            </label>
                                            <div className="mt-1">
                                                <input required value={email} onChange={e => setEmail(e.target.value)}
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    autoComplete="email"
                                                    className="block w-full border-gray-200 shadow-sm focus:ring-cyan-500 focus:border-cyan-500 sm:text-base p-4"
                                                />
                                            </div>
                                        </div>

                                        <div className="sm:col-span-2">
                                            <label htmlFor="message" className="block text-sm font-medium text-gray-200">
                                                {t("message")} (<span className="text-red-500">*</span>)
                                            </label>
                                            <div className="mt-1">
                                                <textarea required value={message} onChange={e => setMessage(e.target.value)}
                                                    id="message"
                                                    name="message"
                                                    rows={6}
                                                    className="shadow-sm focus:ring-cyan-500 focus:border-cyan-500 block w-full sm:text-base border border-gray-200"
                                                    defaultValue={''}
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="message" className="block text-sm font-medium text-gray-200">
                                                {t("capcha")} (<span className="text-red-500">*</span>)
                                            </label>
                                            <div className="mt-1">
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                sitekey="6LdP2P4eAAAAAM-toR5bMPaaug4T5cmDc7advojx"
                                                onChange={onChange}
                                            />
                                            </div>
                                        </div>
                                        {error && <div className="text-red-500">{error}</div>}
                                        <button
                                             type="submit"
                                            className="inline-flex items-center justify-center max-w-fit inline px-8 py-3 text-base font-medium bg-cyan-900 border border-transparent text-slate-100 hover:bg-teal-600"
                                        >{t("send")}</button>

                                    </div>
                                </div>

                            </div>

                        </form>

                    </div>
                    <div className="relative flex flex-col items-center max-w-3xl px-6 py-10 mx-auto text-left sm:my-5 lg:px-0">
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center">
                            <span className="px-3 bg-white text-lg font-medium text-gray-900">{t("others_contact")}</span>
                        </div>
                    </div>
                    <div className="relative flex flex-col items-center max-w-8xl px-6 mx-auto text-left sm:my-5 lg:px-0">
                        <div className="grid grid-cols-1 gap-1 items-start sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 lg:gap-16">
                            <div className="grid grid-cols-1 gap-2 mb-5 text-center">
                                <h1 className="max-w-3xl text-1xl font-bold tracking-tight text-gray-100 lg:text-2xl first-letter:uppercase">{t("email")} </h1>
                                <p className="max-w-full mt-4 text-base text-gray-400 text-justify">{t("email_sepro")}</p>
                            </div>
                            <div className="grid grid-cols-1 gap-2 mb-5 text-center">
                                <h1 className="max-w-3xl text-1xl font-bold tracking-tight text-gray-100 lg:text-2xl first-letter:uppercase">{t("address")} </h1>
                                <p className="max-w-full mt-4 text-base text-gray-400 text-center">{t("address_sepro_1")}</p>
                                <p className="max-w-full text-base text-gray-400 text-center">{t("address_sepro_2")}</p>
                            </div>
                            <div className="grid grid-cols-1 gap-2 mb-5 text-center">
                                <h1 className="max-w-3xl text-base font-bold tracking-tight text-gray-100 first-letter:uppercase">{t("phone")} </h1>
                                <p className="max-w-full mt-4 text-base text-gray-400 text-center">{t("phone_sepro")}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-contact">
                    <footer className="bg-transparent">
                        <div className="mx-auto max-w-md py-12 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                            <div className="mt-8 flex justify-center space-x-6">
                                {social.map((item) => (
                                    <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-300" target="_blank"  rel="noreferrer">
                                        <span className="sr-only">{item.name}</span>
                                        <item.icon className="h-10 w-10" aria-hidden="true" />
                                    </a>
                                ))}
                            </div>
                            <p className="mt-8 text-center text-sm text-gray-400">&copy; {year} {t("copyright")}</p>
                        </div>
                    </footer>
                </div>
            </div>
            <ReactTooltip />
        </Layout>
    );
};
export default Contact;
